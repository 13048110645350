import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ArticleBody from "../components/ArticleBody"
import Seo from "../components/Seo"
import { nonBreakable } from "../constants/helpers"

export default function Page({ data }) {
  // Data destructuring
  const { page, seo } = data
  const { body } = page
  const titleRu = page.title && page.title.ru ? nonBreakable(page.title.ru) : ""
  const titleFi = page.title && page.title.fi ? nonBreakable(page.title.fi) : ""

  return (
    <Layout>
      <Seo seoData={seo} />
      <section className="page-header">
        <h1 className="page-title">
          <span className="russian" lang="ru">
            {titleRu}
          </span>
          <span className="finnish" lang="fi">
            {titleFi}
          </span>
        </h1>
      </section>
      <ArticleBody body={body} />
    </Layout>
  )
}

export const query = graphql`
  fragment PortableText on SanityBlock {
    _key
    _type
    list
    style
    children {
      _key
      _type
      marks
      text
    }
  }

  fragment PageContentBodyCustom on SanityBlockButtonOrBlockCardOrBlockGalleryOrBlockHeadingOrBlockHighlightOrBlockImageOrBlockLocaleLinkGroupOrBlockLocaleTextMinOrBlockSpacerOrBlockVideoOrBlockWidget {
    ... on SanityBlockLocaleTextMin {
      _key
      _type
      _rawContentRu
      _rawContentFi
      contentFi {
        ...PortableText
      }
      contentRu {
        ...PortableText
      }
    }
    ... on SanityBlockHeading {
      _key
      _type
      heading {
        _key
        _type
        fi
        ru
      }
    }
    ... on SanityBlockHighlight {
      _key
      _type
      highlight {
        _type
        fi
        ru
      }
      author {
        _type
        fi
        ru
      }
      subtitle {
        _type
        fi
        ru
      }
    }
    ... on SanityBlockLocaleLinkGroup {
      _key
      _type
      localeCaption {
        ru
        fi
      }
      links {
        ... on SanityBlockLocaleLink {
          _key
          _type
          url
          localeCaption {
            ru
            fi
          }
          mark
          icon
        }
      }
    }
    ... on SanityBlockButton {
      _key
      _type
      caption
      url
    }
    ... on SanityBlockCard {
      _key
      _type
      author {
        _key
        _type
        fi
        ru
      }
      content {
        _key
        _rawContentFi
        _rawContentRu
        contentFi {
          ...PortableText
        }
        _type
        contentRu {
          ...PortableText
        }
      }
      image {
        _key
        _type
        alt
        asset {
          _id
          metadata {
            dimensions {
              aspectRatio
              height
              width
            }
          }
        }
      }
      links {
        _key
        _type
        localeCaption {
          _key
          _type
          fi
          ru
        }
        icon
        mark
        url
      }
      title {
        _key
        _type
        fi
        ru
      }
      type
    }
    ... on SanityBlockImage {
      _key
      _type
      alt
      asset {
        _id
      }
      caption
      width
      copyrightUrl
    }
    ... on SanityBlockGallery {
      _key
      _type
      width
      images {
        _key
        _type
        alt
        caption
        copyrightUrl
        asset {
          _id
        }
      }
    }
    ... on SanityBlockSpacer {
      _key
      _type
      height
    }
    ... on SanityBlockVideo {
      _key
      _type
      video {
        asset {
          _id
          url
        }
      }
      caption
      autoplay
    }
    ... on SanityBlockWidget {
      _key
      _type
      type
      url
      caption
      autoplay
    }
  }

  query GetPage($id: String) {
    page: sanityPage(_id: { eq: $id }) {
      _id
      title {
        fi
        ru
      }
      body {
        ... on SanityBlockLocaleText {
          _key
          _type
          _rawContentRu
          _rawContentFi
          contentFi {
            ...PortableText
          }
          contentRu {
            ...PortableText
          }
        }
        ... on SanityBlockLocaleText {
          _key
          _type
          _rawContentRu
          _rawContentFi
          contentFi {
            ...PortableText
          }
          contentRu {
            ...PortableText
          }
        }
        ... on SanityBlockHeading {
          _key
          _type
          heading {
            _key
            _type
            fi
            ru
          }
        }
        ... on SanityBlockHighlight {
          _key
          _type
          highlight {
            _type
            fi
            ru
          }
          author {
            _type
            fi
            ru
          }
          subtitle {
            _type
            fi
            ru
          }
        }
        ... on SanityBlockImage {
          _key
          _type
          alt
          caption
          width
          copyrightUrl
          asset {
            _id
          }
        }
        ... on SanityBlockGallery {
          _key
          _type
          width
          images {
            _key
            _type
            alt
            caption
            copyrightUrl
            asset {
              _id
            }
          }
        }
        ... on SanityBlockDivider {
          _key
          _type
          style
          custom
          width
        }
        ... on SanityBlockLocaleButton {
          _key
          _type
          buttonFi {
            _key
            _type
            caption
            url
          }
          buttonRu {
            _key
            caption
            _type
            url
          }
        }
        ... on SanityBlockVideo {
          _key
          _type
          video {
            asset {
              _id
              url
            }
          }
          caption
          autoplay
        }
        ... on SanityBlockWidget {
          _key
          _type
          type
          url
          caption
          autoplay
        }
        ... on SanityBlockCardsGroup {
          _key
          _type
          layout
          cards {
            _key
            _type
            type
            author {
              fi
              ru
            }
            image {
              asset {
                _id
                metadata {
                  dimensions {
                    aspectRatio
                    height
                    width
                  }
                }
              }
              alt
            }
            links {
              _key
              _type
              url
              mark
              icon
              localeCaption {
                fi
                ru
              }
            }
            content {
              _key
              _type
              _rawContentRu
              _rawContentFi
              contentFi {
                ...PortableText
              }
              contentRu {
                ...PortableText
              }
            }
            title {
              _key
              _type
              fi
              ru
            }
          }
        }
        ... on SanityBlockCustomLayout {
          _key
          _type
          layout
          leftBody {
            ...PageContentBodyCustom
          }
          middleBody {
            ...PageContentBodyCustom
          }
          rightBody {
            ...PageContentBodyCustom
          }
        }
      }
    }
    seo: sanityPage(_id: { eq: $id }) {
      _id
      _type
      title {
        fi
        ru
      }
      description {
        fi
        ru
      }
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        metaKeywords
        sharedImage {
          asset {
            _id
          }
        }
        preventIndexing
      }
    }
  }
`
